@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;

@font-face {
    font-family: 'GoogleSans';
    src: url('./assets/fonts/GoogleSans/GoogleSans-Regular-v1.27.ttf') format ('truetype');
    font-weight: 400;
    font-style: normal;
}

@layer base {
    html {
        @apply text-Grey900;
    }
    h1 {
        @apply text-4xl;
        @apply font-semibold;
        @apply my-2;
    }
    h2 {
        @apply text-3xl;
        @apply font-semibold;
        @apply my-2;
    }
    h3 {
        @apply text-2xl;
        @apply my-2;

    }
    a {
        @apply hover:text-LightBlue;
    }
    p {
        @apply my-5;
    }
    nav {
        @apply py-3;
    }
    header {
        background-color: #1a1a1a;
        padding: 0.25rem;
        top: 0;
        position: sticky;
        z-index: 10;
    }
    .header {
        font-family: 'Roboto Mono', sans-serif;
    }
    .devfest {
        display: inline;
        font-size: clamp(1.5rem, 2.25rem, 2.25rem);
        line-height: 2.5rem;
        white-space: wrap;
        transition: font-size 0.3s ease-in-out; 
    }
    .devfest span {
        display: inline-block;
        transition: color 0.3s ease;
    }
    .devfest span:nth-child(1) { color: #4285F4; }
    .devfest span:nth-child(2) { color: #DB4437; } 
    .devfest span:nth-child(3) { color:  #F4B400 ; } 
    .devfest span:nth-child(4) { color: #4285F4; }
    .devfest span:nth-child(5) { color:  #0F9D58 ; } 
    .devfest span:nth-child(6) { color: #DB4437; } 
    .devfest span:nth-child(7) { color:  #F4B400 ; } 

    @media (max-width: 768px) {
        .header {
            max-height: fit-content;
        }
        .devfest {
            font-size: 2.0rem;
            line-height: 1.8rem;
            max-height: fit-content;
        }
        nav {
            @apply max-h-24;
        }
    }
}

@tailwind utilities;